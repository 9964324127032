import { isObject } from './isObject'

export type MergeProperties = <T extends Record<string, any>>(
  baseObject: T,
  newObject: Partial<T>
) => T

export const mergeProperties: MergeProperties = (
  baseObject = {} as any,
  newObject = {} as any
) => {
  const result = { ...baseObject }

  const keys = Object.keys(newObject) as Array<keyof typeof newObject>
  keys.forEach((key) => {
    if (isObject(baseObject[key]) && isObject(newObject[key])) {
      result[key] = mergeProperties(baseObject[key], newObject[key])
    } else {
      result[key] = newObject[key]
    }
  })

  return result
}
