/* eslint-disable react/prop-types */
import React, { FC } from 'react'
import Alert from '@bees-web/nfa-components-react/lib/atoms/Alert'
import Collapse from '@bees-web/nfa-components-react/lib/molecules/Collapse'
import { getCookie } from '../../utils/getCookie'

export interface ErrorStatementProps {
  title?: string
  children?: React.ReactNode
}

const ErrorStatement: FC<ErrorStatementProps> = ({ title = '', children }) => {
  const isNonProd =
    process.env.STAGE?.toLocaleLowerCase() !== 'prod' &&
    process.env.STAGE?.toLocaleLowerCase() !== 'uat'
  const hasDebugCookie = getCookie('debug') === process.env.BUILD_BUILDID

  if (isNonProd || hasDebugCookie) {
    return (
      <Alert attributes={{ type: 'error', isDismissible: true }}>
        <Collapse attributes={{ title: title }}>{children}</Collapse>
      </Alert>
    )
  }

  // hides defective block in prod by default
  return <></>
}

export default ErrorStatement
