//TODO: Implement using ua-parser-js instead
export default function getDeviceType(
  userAgent: string
): 'mobile' | 'tablet' | 'desktop' {
  if (userAgent) {
    const isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
      userAgent
    )
    const isTablet = /Tablet|iPad/.test(userAgent)
    return isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop'
  }
  return 'desktop'
}
