/* eslint-disable security/detect-object-injection */
import cookies from 'next-cookies'
import { domains } from '../../config'
import { ServerContext } from '../../core/interfaces'

export type GetDomainLocale = (context: ServerContext) => null | {
  isSupported: boolean
  supportedLanguages?: string[]
  country?: string
  language?: string
  locale?: string
}

const getDomainLocale: GetDomainLocale = (context) => {
  const {
    req: { headers },
  } = context

  const xForwardedHost = headers['x-forwarded-host']
    ? (headers['x-forwarded-host'] as string)?.split(',')[0]
    : null
  const incommingHost = xForwardedHost ?? headers.host
  const host = incommingHost?.split(':')[0]
  const supportedLocales: string[] = domains[host]

  if (supportedLocales) {
    const supportedLanguages = []
    let domainCountry = ''
    for (const locale of supportedLocales) {
      const [language, country] = locale.split('-')
      if (domainCountry && domainCountry !== country)
        throw new Error(`There is a wrong country set for "${host}"`)

      domainCountry = country
      supportedLanguages.push(language.toLowerCase())
    }

    const { language: cookieLanguage } = cookies(context)
    const { language: queryLanguage } = context.query
    const preferredLanguage =
      (queryLanguage && Array.isArray(queryLanguage)
        ? queryLanguage[0]
        : (queryLanguage as string)) || cookieLanguage

    const language = supportedLanguages.includes(preferredLanguage)
      ? preferredLanguage
      : supportedLanguages[0]

    return {
      isSupported: true,
      supportedLanguages,
      country: domainCountry,
      language,
      locale: `${language}_${domainCountry}`,
    }
  }

  return {
    isSupported: false,
  }
}

export default getDomainLocale
