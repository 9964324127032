import React, { FC, useEffect, useMemo, useState } from 'react'
import Block from './Block'
import { PageProps } from '../interfaces'
import { IApplicationState } from '@bees-web/nfa-types'
import { useDispatch, useSelector } from 'react-redux'
import { useValueStream } from '../../utils/VL'
import { getGlobalActions } from '../../interactive'
import { interactiveGlobalMap } from '../../interactive/interactive-global-map'
import { PageContext } from '../hooks/usePageContext'
// TODO: Initialize state on hydration instead
import { updateUser as initializeState } from '@bees-web/nfa-interactive-global/actions/user/updateUser'
import { FeatureData, featuresStorage } from '@bees-web/web-shared-services'

const ClientContentPage: FC<PageProps> = (props) => {
  const {
    name,
    namespace,
    dependencies,
    locale,
    query,
    applicationId,
    accountId,
    storeId,
    ajsAnonymousId,
    ajsUserId,
    template,
  } = props

  const [initialized, setInitialized] = useState(false)
  const {
    isLoading,
    interactiveMap: processInteractive,
    stateSelector,
  } = useValueStream(namespace, dependencies, true)

  const state = useSelector((applicationState: IApplicationState) =>
    stateSelector(applicationState)
  )

  const dispatch = useDispatch()

  // TODO: Initialize all client-side state
  useEffect(() => {
    if (!isLoading) {
      const [language, country] = locale?.split('_')
      dispatch(initializeState({ country, language }))
      setInitialized(true)
    } else {
      setInitialized(false)
    }
  }, [isLoading, locale, dispatch])

  useEffect(() => {
    // TODO: change type of IPage
    featuresStorage().setData(template?.featureFlags as unknown as FeatureData)
  }, [template])

  const interactiveMap = useMemo(
    () =>
      !initialized
        ? {}
        : {
            ...processInteractive(dispatch, state, getGlobalActions()),
            ...interactiveGlobalMap({ dispatch, state }),
          },
    [dispatch, initialized, processInteractive, state]
  )

  const contextValue = useMemo(
    () => ({
      key: name,
      namespace,
      locale,
      query,
      applicationId,
      accountId,
      storeId,
      ajsAnonymousId,
      ajsUserId,
      interactiveMap,
      state,
    }),
    [
      name,
      namespace,
      locale,
      query,
      applicationId,
      accountId,
      storeId,
      ajsAnonymousId,
      ajsUserId,
      interactiveMap,
      state,
    ]
  )

  return initialized ? (
    <PageContext.Provider value={contextValue}>
      {template.blocks.map((block) => (
        <Block key={block.name} template={block} />
      ))}
    </PageContext.Provider>
  ) : null
}

export default ClientContentPage
