/* eslint-disable brace-style */
/* eslint-disable security/detect-object-injection */
import { dependencies } from '../../config'

export type GetCustomComponent = (name: string) => unknown

let _allCustomComponents: { [key: string]: unknown } | null = null

const getCustomComponent: GetCustomComponent = (name) => {
  const parts = name?.split('.') || []
  const [namespace, componentName] = parts

  if (parts.length === 2) {
    const config = dependencies[namespace]
    const component = config?.components?.[componentName]
    return component || null
  } else if (parts.length === 1) {
    if (!_allCustomComponents) {
      _allCustomComponents = Object.keys(dependencies).reduce(
        (previous, curr) => ({ ...previous, ...dependencies[curr].components }),
        {}
      )
    }
    return _allCustomComponents[namespace] || null
  }

  return null
}

export default getCustomComponent
