import getMappedRoutes, { PagesRouter } from '../../utils/VL/getMappedRoutes'

let _routes: PagesRouter | null = null

export default function getApplicationRoutes(): PagesRouter {
  if (!_routes) {
    _routes = getMappedRoutes()
  }

  return _routes
}
