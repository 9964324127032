import UAParser from 'ua-parser-js'

export default function isPotentiallyLowEndDevice(userAgent: string): boolean {
  const parser = new UAParser(userAgent)
  const osName = parser.getOS().name
  const osVersion = parseFloat(parser.getOS().version)
  const browserName = parser.getBrowser().name
  const browserVersion = parseFloat(parser.getBrowser().version)
  const deviceMemory =
    (typeof window !== 'undefined' && window.navigator['deviceMemory']) || 0
  const hardwareConcurrency =
    (typeof window !== 'undefined' && window.navigator.hardwareConcurrency) || 1

  // Check for indicators of older or less capable devices
  if (
    (osName === 'iOS' && osVersion < 10) ||
    (osName === 'Android' && osVersion < 10) ||
    (browserName === 'IE' && browserVersion < 11) ||
    (browserName === 'Safari' && browserVersion < 9) ||
    osName === 'Windows Phone' ||
    osName === 'Windows Mobile' ||
    (osName === 'Windows' &&
      osVersion < 8 &&
      (browserName === 'Edge' || deviceMemory < 4 || hardwareConcurrency < 2))
  ) {
    return true
  }

  return false
}
