/* eslint-disable security/detect-object-injection */
import { isObject } from './isObject'

/**
 * Deep merge two or more objects.
 * @param target
 * @param ...sources
 */
export function deepMerge<T>(target: T, ...sources): T {
  if (sources.length === 0) return target

  const [source, ...restSources] = sources

  if (isObject(target) && isObject(source)) {
    Object.entries(source).forEach(([key, value]) => {
      if (isObject(value)) {
        if (!target[key]) target[key] = {}
        deepMerge(target[key], value)
      } else {
        target[key] = value === undefined ? null : value
      }
    })
  }

  return deepMerge(target, ...restSources)
}
