/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable brace-style */
/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import Block, { BlockProps } from './Block'
import { getDeferredUrl } from '../tools/joker'
import { useBlockSchema, usePageContext, useReadyToFetch } from '../hooks'
import ErrorStatement from './ErrorStatement'

const FallbackBlock = ({ template }: BlockProps) => {
  const defaultBlock = {
    ...template,
    deferred: null,
  }
  return <Block template={defaultBlock} />
}

const AsyncBlock = ({ template }: BlockProps) => {
  const { url } = getDeferredUrl(template)
  const { block, isLoading, error } = useBlockSchema(url)

  if (isLoading || !block) return <FallbackBlock template={template} />
  if (error)
    return (
      <ErrorStatement title="Deferred load error">
        {error.message}
      </ErrorStatement>
    )

  return (
    <Block
      template={{
        ...template,
        blocks: block.blocks,
        deferred: null,
      }}
    />
  )
}

const DeferredBlock = (props: BlockProps) => {
  const {
    template: { waitForState = [] },
  } = props

  const { state } = usePageContext()

  const readyToFetch = useReadyToFetch(waitForState, state)

  return readyToFetch ? <AsyncBlock {...props} /> : <FallbackBlock {...props} />
}

export default DeferredBlock
