/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'
import { deepMerge } from '../../utils/deepMerge'

export type ClientServerReconcilier = (
  reducer: Reducer,
  selector: (payload: any) => any,
  hydrate?: (state, payload, deepMerge) => any
) => Reducer

// Should be used together client-server redux intended actions
const clientServerReconcilier: ClientServerReconcilier = (
  reducer,
  selector,
  hydrate
) => {
  return (state, action): any => {
    if (action.type === HYDRATE) {
      const payload = selector(action.payload)

      // If there is no set hydrate handler client-server state will be deep merged
      if (hydrate) return { ...hydrate(state, payload, deepMerge) }
      return { ...state, ...payload } //deepMerge(payload, state)
    }
    return reducer(state, action)
  }
}

export default clientServerReconcilier
