/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import React, { FC, useMemo } from 'react'
import { ServerPageProps } from '../interfaces'
import { SWRConfig } from 'swr'
import ClientContentRender from './ClientContentPage'
import MetaPage from './MetaPage'
import { NextRouter, useRouter } from 'next/router'

const swrConfig = (
  fallback: Record<string, unknown> = {},
  router: NextRouter
) => ({
  fallback,
  revalidateIfStale: false,
  errorRetryCount: 5,
  errorRetryInterval: 10000,
  dedupingInterval: 60000,
  revalidateOnFocus: false,
  onError: (error) => {
    if (error.response) {
      const { status, data, headers } = error.response
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(data)
      console.log(status)
      console.log(headers)
      if (status === 401) {
        // || status === 409) {
        const [path, query] = router.asPath.split('?')
        router.push(`/login?redirect_path=${path}${query ? `&${query}` : ''}`)
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    console.log(error.config)
  },
})

const ServerPage: FC<ServerPageProps> = ({ swrCache, ...props }) => {
  const isClient = useMemo(() => typeof window !== 'undefined', [])
  const router = useRouter()

  return (
    <SWRConfig value={swrConfig(swrCache, router)}>
      <MetaPage template={props.template} scripts={props.scripts}>
        {isClient && <ClientContentRender {...props} />}
      </MetaPage>
    </SWRConfig>
  )
}

export default ServerPage
