import { useState, useEffect } from 'react'
import {
  DynamicVSConfiguration,
  IApplicationState,
  SimpleVSConfiguration,
} from '@bees-web/nfa-types'
import { UseInteractive } from '../../../interfaces'
import { dependencies } from '../../../config'
import { AppState, loadContextualStore } from '../../../core/store'
import { createSelector } from '@reduxjs/toolkit'
import { loadComponentPack } from '../getComponent'

export type UseValueStreamProps = {
  interactiveMap: UseInteractive
  isLoading: boolean
  stateSelector: (state: AppState) => IApplicationState
}

const combineInteractives =
  (interactives: UseInteractive[] = []): UseInteractive =>
  (dispatch, state, globalActions) => {
    try {
      return interactives.reduce((prev, curr) => {
        return {
          ...prev,
          ...(typeof curr === 'function'
            ? curr(dispatch, state, globalActions)
            : {}),
        }
      }, {})
    } catch (e) {
      console.log('Error combining interactives', e)
    }
  }

function getModulesToImport(
  namespace: string,
  pageDependencies: string[]
): string[] {
  const vs = dependencies[namespace]
  if (vs) {
    const vsDependencies =
      (vs as DynamicVSConfiguration).dependencies ||
      (vs as SimpleVSConfiguration).state?.dependencies

    return [...(pageDependencies || vsDependencies || []), namespace]
  }
  return null
}

const useValueStream = (
  namespace: string,
  pageDependencies: string[],
  loadComponents = false
): UseValueStreamProps => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [modulesImported, setModulesImported] = useState<string[]>([])
  const [interactiveMap, setInteractiveMap] = useState<UseInteractive | null>(
    null
  )

  useEffect(() => {
    const importDependencies = async (): Promise<void> => {
      const modulesToImport = getModulesToImport(namespace, pageDependencies)
      setIsLoading(true)
      if (modulesToImport) {
        try {
          const [modules] = await Promise.all([
            loadContextualStore(modulesToImport),
            ...(loadComponents ? [loadComponentPack()] : []),
          ])
          if (modules) {
            setModulesImported(modulesToImport)
            setInteractiveMap(() =>
              combineInteractives(
                Object.values(modules).map(
                  (module) => module.actions?.interactiveMap
                )
              )
            )
          }
        } catch (error) {
          console.error(
            `Error loading modules [${modulesToImport.join(',')}]: ${
              error.message
            }`
          )
        }
      }
      setIsLoading(false)
    }
    importDependencies()
  }, [namespace, pageDependencies, loadComponents])

  return {
    interactiveMap,
    isLoading,
    stateSelector: createSelector([(state: AppState) => state], (state) => ({
      ...state,
      state:
        modulesImported && !isLoading
          ? modulesImported.reduce(
              (prev, curr) => ({ ...prev, ...state[curr] }),
              {}
            )
          : state,
    })),
  }
}

export default useValueStream
