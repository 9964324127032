/* eslint-disable security/detect-object-injection */
import getApplicationRoutes from '.'
import sitemap from '../../sitemap/sitemap.json'

const routing = getApplicationRoutes()

export type GetSiteMap = () => {
  files: { [key: string]: boolean }
  dynamicRoutes: { [key: string]: boolean }
  routes: { [key: string]: boolean }
}

const getSiteMap: GetSiteMap = () => {
  for (const route in routing.dynamics) {
    Object.keys(routing.dynamics[route])
      .map((route) => route.replace(':id', '[id]'))
      .forEach((route) => {
        sitemap.dynamicRoutes[route] = true
        sitemap.routes[route] = true
      })
  }
  Object.keys(routing.statics).forEach(
    (route) => (sitemap.routes[route] = true)
  )

  return sitemap
}

export default getSiteMap
