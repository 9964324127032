export const notFound = {
  redirect: {
    permanent: false,
    destination: '/not_found',
  },
}

export const serviceUnavailable = {
  redirect: {
    permanent: false,
    destination: '/service_unavailable',
  },
}

export const notAuthorized = (
  country: string,
  language: string,
  redirectUrl: string
) => ({
  redirect: {
    permanent: false,
    destination: `/api/auth?p=B2C_1A_SIGNIN_${country}&country=${country}&language=${language}&redirect_path=${redirectUrl}`,
  },
})
